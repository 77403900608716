import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyCode } from 'gain-lib/money';
import { updateRouteDataValues } from 'gain-web/lib/router/find-route-data';
import { getRouteParamFromRoot } from 'gain-web/lib/router/find-route-param';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { CalculationContextService } from './calculation-context.service';
import { ClientContextService } from 'gain-web/app/clients/client-shared/client-context.service';

@Injectable()
export class CalculationContextWrapperService implements OnDestroy {
  private _calculationContext$: BehaviorSubject<ApiClient.CalculationContextDto>;

  public get value(): ApiClient.CalculationContextDto {
    return this._calculationContext$.value;
  }

  public get valueChanges(): Observable<ApiClient.CalculationContextDto> {
    return this._calculationContext$.asObservable();
  }

  private _onDestroy$ = new Subject();
  private _client: ApiClient.ClientDto;

  constructor(
    private _router: Router,
    private _apiCalculationContextService: ApiClient.CalculationContextService,
    private _calculationContextService: CalculationContextService,
    private _clientContextService: ClientContextService,
  ) {
    this._client = this._clientContextService.value.client;

    this._calculationContext$ =
      new BehaviorSubject<ApiClient.CalculationContextDto>(
        this._calculationContextService.value,
      );

    this._calculationContextService.valueChanges
      .pipe(
        startWith(this._calculationContext$.value),
        distinctUntilChanged(isEqual),
        takeUntil(this._onDestroy$),
        catchError((_, caught) => caught),
      )
      .subscribe((calculationContext) =>
        this._calculationContext$.next(calculationContext),
      );
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async refreshCalculationContext() {
    const route = this._router.routerState.root.snapshot;
    const portalId = getRouteParamFromRoot(route, 'portalId');
    const calculationId = getRouteParamFromRoot(route, 'calculationId');

    const ccr = await this._apiCalculationContextService
      .getCalculationContextById(portalId, calculationId)
      .toPromise();

    updateRouteDataValues(route, 'calculationContext', ccr.result);

    this._calculationContext$.next(ccr.result);
  }

  async recomputeCalculationResults(currencyCode: CurrencyCode | string) {
    const route = this._router.routerState.root.snapshot;
    const calculationId = getRouteParamFromRoot(route, 'calculationId');
    const transactionGuid = getRouteParamFromRoot(route, 'transactionGuid');
    const recomputeArgs = new ApiClient.RecomputeCalculationResultsCommand({
      clientId: this._client.id,
      calculationId: calculationId,
      transactionGuid: transactionGuid,
      currencyCode: currencyCode,
      decimalNumberPrecision: null,
      percentagePrecision: null,
    });

    return await this._apiCalculationContextService
      .recomputeCalculationResults(recomputeArgs)
      .toPromise();
  }
}
