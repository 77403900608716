<div class="nav-wrapper">
  <div class="nav-client-name">{{ this.clientName }} - {{ this.clientId }}</div>
  <div class="tab-wrapper">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a
        *ngFor="let link of links$ | async"
        mat-tab-link
        [active]="(activeLink$ | async)?.path === link.path"
        routerLink="{{ link.path }}"
        [disabled]="link.disabled"
        >{{ link.name }}</a
      >
    </nav>
  </div>
</div>
<div class="tab-content-wrapper">
  <mat-tab-nav-panel #tabPanel>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-tab-nav-panel>
</div>
