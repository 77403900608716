import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { findRouteData } from 'gain-web/lib/router/find-route-data';
import { getRouteParamFromRoot } from 'gain-web/lib/router/find-route-param';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CalculationContextService {
  public get value(): ApiClient.CalculationContextDto {
    return getCalculationContext(this._router.routerState.root.snapshot);
  }

  public get valueChanges(): Observable<ApiClient.CalculationContextDto> {
    return this._router.events.pipe(map(() => this.value));
  }

  constructor(private _router: Router) {}
}

export function getCalculationContext(
  route: ActivatedRouteSnapshot,
): ApiClient.CalculationContextDto {
  const calculationContext = findCalculationContext(route);
  if (calculationContext == null) {
    throw new Error('Unable to find calculation context in route tree');
  }
  return calculationContext;
}

export function findCalculationContext(
  route: ActivatedRouteSnapshot,
): ApiClient.CalculationContextDto | undefined {
  return findRouteData<ApiClient.CalculationContextDto>(
    route,
    'calculationContext',
  );
}

export async function findOrFetchCalculationContext(
  route: ActivatedRouteSnapshot,
  calculationContextService: ApiClient.CalculationContextService,
): Promise<ApiClient.CalculationContextDto> {
  let calculationContext = findCalculationContext(route);
  if (calculationContext == null) {
    const portalId = getRouteParamFromRoot(route, 'portalId');
    const calculationId = getRouteParamFromRoot(route, 'calculationId');
    const ccr = await calculationContextService
      .getCalculationContextById(portalId, calculationId)
      .toPromise();
    calculationContext = ccr.result;
  }
  return calculationContext;
}

export function findTransactionInputByGuid(
  inputs: ApiClient.CalculationEngineInputs,
  transactionGuid: string,
) {
  return [
    ...inputs.employee.cashBonusTransactions,
    ...inputs.employee.restrictedStockUnitTransactions,
    ...inputs.employee.stockOptionTransactions,
  ].find((t) => t.transactionGuid === transactionGuid);
}

export function getTransactionInputs(
  calcContext: ApiClient.CalculationContextDto,
  snapshot: ActivatedRouteSnapshot,
) {
  const transactionGuid = getRouteParamFromRoot(snapshot, 'transactionGuid');
  const inputs =
    calcContext.calcEngineInputs != null
      ? findTransactionInputByGuid(
          calcContext.calcEngineInputs,
          transactionGuid,
        )
      : undefined;

  if (inputs == null) {
    throw new Error(`unable to find transaction with GUID: ${transactionGuid}`);
  }

  return inputs;
}

export function getTransactionResult(
  calcContext: ApiClient.CalculationContextDto,
  snapshot: ActivatedRouteSnapshot,
) {
  const transactionGuid = getRouteParamFromRoot(snapshot, 'transactionGuid');
  const results = calcContext.calcEngineResults?.transactionResults.find(
    (t) => t.transactionGuid === transactionGuid,
  );

  if (results == null) {
    throw new Error(`unable to find transaction with GUID: ${transactionGuid}`);
  }

  return results;
}
