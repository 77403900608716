import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { getRouteParamFromRoot } from 'gain-web/lib/router/find-route-param';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { ClientContextService } from './client-context.service';

@Injectable()
export class ClientContextWrapperService implements OnDestroy {
  private _clientContext$: BehaviorSubject<ApiClient.ClientContextDto>;

  public get value(): ApiClient.ClientContextDto {
    return this._clientContext$.value;
  }

  public get valueChanges(): Observable<ApiClient.ClientContextDto> {
    return this._clientContext$.asObservable();
  }

  private _onDestroy$ = new Subject();

  constructor(
    private _router: Router,
    private _apiClientContextService: ApiClient.ClientContextService,
    private _clientContextService: ClientContextService,
  ) {
    this._clientContext$ = new BehaviorSubject<ApiClient.ClientContextDto>(
      this._clientContextService.value,
    );

    this._clientContextService.valueChanges
      .pipe(
        startWith(this._clientContext$.value),
        distinctUntilChanged(isEqual),
        takeUntil(this._onDestroy$),
        catchError((_, caught) => caught),
      )
      .subscribe((clientContext) => this._clientContext$.next(clientContext));
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async refreshClientContext() {
    const route = this._router.routerState.snapshot.root;
    const portalId = getRouteParamFromRoot(route, 'portalId');

    const ccr = await this._apiClientContextService
      .getClientContextByPortalId(portalId)
      .toPromise();

    this._clientContext$.next(ccr.result);
  }
}
