import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { switchMap, scan, takeUntil, map, takeWhile } from 'rxjs/operators';
import { ApiClient } from '../../shared-services/api-client.generated.service';
import { ClientContextService } from '../../app/clients/client-shared/client-context.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class VirusScanningService {
  constructor(
    private _virusScanningService: ApiClient.VirusScanningService,
    private _clientContextService: ClientContextService,
    private _snackBar: MatSnackBar,
  ) {}

  public async pollVirusScanningJobs(
    virusScanningJobIds: string[],
    onVirusScanningJobsSuccess: () => Promise<void>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    takeUntil$: Observable<any>,
  ) {
    const maxRetries = 60;

    try {
      const virusScanningResult = await interval(3000)
        .pipe(
          switchMap(() =>
            this._virusScanningService.getVirusScanningJobsStatus(
              this._clientContextService.value.client.id,
              virusScanningJobIds,
            ),
          ),
          map((_) => _.virusScanningJobs),
          scan(
            (acc, _) => {
              acc.allJobsSuceeded =
                _.filter(
                  (_) =>
                    _.status === ApiClient.VirusScanningJobStatus.ScanSuccess,
                ).length === _.length;

              if (!acc.allJobsSuceeded) {
                acc.retries += 1;
              }

              return acc;
            },
            { retries: 0, allJobsSuceeded: false },
          ),
          takeWhile((_) => _.retries < maxRetries && !_.allJobsSuceeded, true),
          takeUntil(takeUntil$),
        )
        .toPromise();

      if (virusScanningResult.retries >= maxRetries) {
        this._showSnackBar('Virus scanning timed out');
      } else if (!virusScanningResult.allJobsSuceeded) {
        this._showSnackBar('Virus scanning failed');
      } else {
        await onVirusScanningJobsSuccess();
      }
    } catch {
      this._showSnackBar(
        'Unknown error occurred virus scanning files. Please try again.',
      );
    }
  }

  private _showSnackBar(message: string) {
    this._snackBar.open(message, 'Close', {
      duration: 1000 * 5,
    });
  }
}
