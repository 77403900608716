import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';
import { RuntimeConfigurationService } from 'gain-web/shared-modules/runtime-configuration/runtime-configuration.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { orderBy } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environmentType } from '../../client-admin/client-admin-reports/client-admin-reports-model';
import { ClientContextService } from '../client-context.service';

interface Link {
  name: string;
  path: string;
  disabled: boolean;
}

@Component({
  selector: 'app-client-content',
  templateUrl: './client-content.component.html',
  styleUrls: ['./client-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class ClientContentComponent implements OnDestroy {
  clientRootPath: string;
  clientName: string;
  clientId: string;
  links$: BehaviorSubject<Link[]> = new BehaviorSubject<Link[]>([]);
  activeLink$ = new BehaviorSubject<Link | undefined>(undefined);
  private _onDestroy$ = new Subject();
  currentEnvironment: environmentType | undefined;
  get activeLink() {
    return orderBy(this.links$.getValue(), (l) => l.path.length, 'desc').find(
      (l) => this._router.url.toLowerCase().includes(l.path),
    );
  }

  get hideChangeRequests(): boolean {
    const environmentList = [
      'dev',
      'stage_us',
      'stage_eu',
      'stage_pen',
      'stage_bu_eu',
      'prod_us',
      'prod_eu',
    ];
    return environmentList.includes(this.currentEnvironment?.environment ?? '');
  }

  constructor(
    private runtimeConfigService: RuntimeConfigurationService,
    private _router: Router,
    private _clientContextService: ClientContextService,
  ) {
    this.clientName = this._clientContextService.value.client.name;
    this.clientId = this._clientContextService.value.client.portalId;
    this.clientRootPath = `/clients/${this._clientContextService.value.client.portalId}`;
    this.currentEnvironment = this.runtimeConfigService.get().reportsPromotion;

    this.setLinks(this._clientContextService.value);
    this._clientContextService.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((clientContext) => this.setLinks(clientContext));

    this.activeLink$.next(this.activeLink);

    this._router.events
      .pipe(
        filter((e) => e instanceof GuardsCheckEnd),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        this.activeLink$.next(this.activeLink);
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private setLinks(clientContext: ApiClient.ClientContextDto) {
    const environmentList = ['dev', 'qa', 'pre_prod_us', 'pre_prod_eu'];
    const topNavLinks = [
      {
        name: 'Configuration',
        path: `${this.clientRootPath}/configuration`,
        disabled: false,
      },
      {
        name: 'Calculations',
        path: `${this.clientRootPath}/calculations`,
        disabled: !clientContext.calculationsAreEnabled,
      },
      {
        name: 'Reporting',
        path: `${this.clientRootPath}/reports`,
        disabled: false,
      },
      {
        name: 'Overrides',
        path: `${this.clientRootPath}/overrides`,
        disabled: false,
      },
      {
        name: 'Data Exchange',
        path: `${this.clientRootPath}/data-exchange`,
        disabled: false,
      },

      {
        name: 'Admin',
        path: `${this.clientRootPath}/admin`,
        disabled: false,
      },
    ];

    const indexNumber = topNavLinks.map((e) => e.name).indexOf('Data Exchange');
    if (!this.hideChangeRequests) {
      const changeRequest = {
        name: 'Change Request',
        path: `${this.clientRootPath}/change-request`,
        disabled: false,
      };
      environmentList.splice(indexNumber + 1, 0);
      if (
        environmentList.includes(this.currentEnvironment?.environment ?? '')
      ) {
        topNavLinks.splice(indexNumber + 1, 0, changeRequest);
      }
    }
    this.links$.next(topNavLinks);
  }
}
