import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from 'gain-web/shared-modules/breadcrumbs/breadcrumbs.module';
import { SharedEssentialsModule } from 'gain-web/shared-modules/shared-essentials/shared-essentials.module';
import { CalculationContextWrapperService } from '../client-calculations/calculation-context-wrapper.service';
import { CalculationContextService } from '../client-calculations/calculation-context.service';
import { ClientContentComponent } from './client-content/client-content.component';
import { ClientContextWrapperService } from './client-context-wrapper.service';
import { ClientContextService } from './client-context.service';
import { SnackBarService } from './snack-bar.service';
import { VirusScanningService } from '../../../lib/virus-scanning/virus-scanning.service';

@NgModule({
  declarations: [ClientContentComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule,
    MatSidenavModule,
    BreadcrumbsModule,
    SharedEssentialsModule,
  ],
  exports: [ClientContentComponent, MatSidenavModule, SharedEssentialsModule],
  providers: [
    CalculationContextService,
    CalculationContextWrapperService,
    ClientContextService,
    ClientContextWrapperService,
    SnackBarService,
    VirusScanningService,
  ],
})
export class ClientSharedModule {}
