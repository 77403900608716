import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService implements OnDestroy {
  private routerSubscription: Subscription;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.closeSnackBar();
      }
    });
  }

  openSnackBar(message: string) {
    this.openSnackBarWithAction(message, 'X');
  }

  openSnackBarWithAction(message: string, action: string) {
    this.snackBar.open(message, action, {
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['snackbar-custom-style'],
    });
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
